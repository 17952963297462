import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";

const Login = () => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const openNotificationWithIcon = (type, message) => {
        api[type]({
            message: message,
        });
    };
    const navigate = useNavigate();

    const handleSubmit = (values) => {
        setIsLoading(true);
        axios
            .post(`${process.env.REACT_APP_BASE_URL}login`, values, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response?.response?.status === 400) {
                    openNotificationWithIcon("error", response?.response?.data?.message);
                } else if (response?.data?.user) {
                    sessionStorage.setItem("userDetails", JSON.stringify(response?.data?.user));
                    openNotificationWithIcon("success", "Login successfully");
                    navigate("../message-list");
                    window.location.reload(true);
                }
            })
            .catch((error) => {
                openNotificationWithIcon("error", "Something went wrong");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    return (
        <>
            {contextHolder}
            <Spin spinning={isLoading} fullscreen />
            <div
                className="flex justify-center items-center h-[100vh]"
                style={{ background: "rgb(17, 24, 39)" }}>
                <div className="shadow-md rounded p-[24px] text-white">
                    <h2 className="text-[24px] font-bold text-center my-4">
                        Sign in to your account
                    </h2>
                    <Formik
                        initialValues={{ email: "", password: "" }}

                        onSubmit={handleSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="mb-4">
                                    <label
                                        className="block  text-sm font-bold mb-2"
                                        htmlFor="email">
                                        Email
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-[400px] h-[50px] py-2 px-3  
                  leading-tight focus:outline-none focus:shadow-outline text-black"
                                        id="email"
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-sm font-bold mb-2"
                                        htmlFor="password">
                                        Password
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-[400px] h-[50px] py-2 px-3 
                  leading-tight focus:outline-none focus:shadow-outline text-black"
                                        id="password"
                                        type="password"
                                        name="password"
                                        placeholder="password"
                                    />
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        className={`bg-blue-500 hover:bg-blue-700 w-full h-[45px] text-white font-bold py-2 
                  px-4 rounded focus:outline-none focus:shadow-outline`}
                                        type="submit"
                                    >
                                        {isSubmitting ? "Logging in..." : "Log In"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default Login;