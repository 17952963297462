import React from 'react';

const TermConditions = () => {
    return (
        <div className="p-6 bg-white text-gray-800 max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">BRAND4HUB Terms and Conditions</h1>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
                <p>
                    Welcome to BRAND4HUB. These Terms and Conditions outline the rules and regulations for the use of our website, <a href="https://www.brand4hub.com" className="text-blue-500 underline">https://www.brand4hub.com</a>. By accessing this website, you agree to be bound by these Terms and Conditions.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">2. Use of the Site</h2>
                <p>
                    You must be at least 18 to 80 years old or have parental consent to use this site. You are responsible for maintaining the confidentiality of your account and password. Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">3. Intellectual Property Rights</h2>
                <p>
                    All content on this website, including text, graphics, logos, images, and software, is the property of BRAND4HUB and is protected by copyright laws. You may not reproduce, distribute, or create derivative works from any content on the website without our express written permission.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">4. Products and Services</h2>
                <p>
                    BRAND4HUB strives to provide accurate product descriptions but does not guarantee that product descriptions or other content are accurate, complete, reliable, current, or error-free. We reserve the right to modify or discontinue any product or service without notice.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">5. Pricing and Payment</h2>
                <p>
                    All prices will be in INR and will be converted into local currency as per the website it is accessing. We accept payments through both online and offline methods. Payment is due at the time of purchase. In case of pricing errors, we reserve the right to cancel any orders that are affected.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">6. Shipping and Delivery</h2>
                <p>
                    We offer shipping to all countries listed. Shipping fees and estimated delivery times are displayed during checkout. Delivery times are estimates and not guaranteed. BRAND4HUB is not responsible for any delays caused by shipping carriers.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">7. Returns and Refunds</h2>
                <p>
                    You may return most new, unopened items within 2 days of delivery for a full refund. Return shipping costs will be paid by the customer, unless the return is due to our error. Refunds will be processed within 2 days after we receive your returned item.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">8. User-Generated Content</h2>
                <p>
                    Users may submit reviews, comments, and other content as long as it is not illegal, obscene, threatening, defamatory, or infringing on intellectual property rights. BRAND4HUB reserves the right to remove or edit any content that violates these guidelines.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">9. Privacy Policy</h2>
                <p>
                    We are committed to protecting your privacy. Our GDPR compliance and data protection policy explain how we collect, use, and share your personal information. By using our website, you consent to the collection and use of your information as described in our Privacy Policy.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">10. Disclaimer of Warranties</h2>
                <p>
                    All products and services are provided "as is" and "as available" without any warranties, either express or implied. BRAND4HUB does not warrant that the website will be uninterrupted or error-free, or that any defects will be corrected.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">11. Limitation of Liability</h2>
                <p>
                    BRAND4HUB will not be liable for any damages of any kind arising from the use of this website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">12. Indemnification</h2>
                <p>
                    You agree to indemnify, defend, and hold harmless BRAND4HUB, its employees, directors, agents, and affiliates from and against all claims, liabilities, damages, losses, or expenses arising from your use of the website.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">13. Governing Law</h2>
                <p>
                    These Terms and Conditions shall be governed by and construed by the laws of India/Bangalore. Any disputes arising under or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of India/Bangalore.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">14. Changes to Terms and Conditions</h2>
                <p>
                    We reserve the right to modify these Terms and Conditions at any time. Changes will be posted on this page, and your continued use of the site constitutes acceptance of the revised terms.
                </p>
            </section>

            <section className="mb-4">
                <h2 className="text-xl font-semibold mb-2">15. Contact Information</h2>
                <p>
                    For any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:info@brand4hub.com" className="text-blue-500 underline">info@brand4hub.com</a>.
                </p>
            </section>
        </div>
    );
}

export default TermConditions;
