import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pagination } from 'antd';

const MessageList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);

    const onChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}message/list`, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                page: currentPage,
            }
        })
            .then((response) => {
                setData(response?.data);
                setTotal(response?.data?.totalMessages)
                setCurrentPage(response?.data?.currentPage);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentPage]);

    return (
        <div className='p-4'>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left font-medium uppercase tracking-wider">Full Name</th>
                                <th scope="col" className="px-6 py-3 text-left font-medium uppercase tracking-wider">Email</th>
                                <th scope="col" className="px-6 py-3 text-left font-medium uppercase tracking-wider">Phone Number</th>
                                <th scope="col" className="px-6 py-3 text-left font-medium uppercase tracking-wider">Name Of Brand</th>
                                <th scope="col" className="px-6 py-3 text-left font-medium uppercase tracking-wider">Notes</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data?.messages?.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">{item.fullName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.phone}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.nameOfBrand}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{item.notes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='flex justify-center my-3'>
                        <Pagination current={currentPage} onChange={onChange} total={total} />
                    </div>
                </>
            )}
        </div>
    );
}

export default MessageList;
