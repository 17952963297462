// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/image2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100vh;
  background-size: cover;
  background-position: center;
  letter-spacing: 1px;
  word-spacing: 1px;
}

.logoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0px 20px 0px;
}

.LOGO1 {
  width: 160px;
  height: 160px;
}

.LOGO1 img {
  width: 100%;
  height: 100%;
}

.LOGO2 {
  width: 250px;
}

.LOGO2 img {
  width: 100%;
  height: 100%;
}

.title1 {
  font-size: 32px;
  font-weight: 600;
  margin-top: 10px;
}

.title2 {
  font-size: 14px;
  font-weight: 600;
  color: red;
  letter-spacing: 5px;
  margin-bottom: 10px;
}

.title3 {
  font-size: 32px;
}

.title4 {
  font-size: 12px;
}

.button {
  background-color: white;
  cursor: pointer;
  padding: 8px 15px;
  font-weight: 600;
  margin: 10px 0px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yDAA4C;EAC5C,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".Home {\n  background-image: url(\"./images/image2.png\");\n  height: 100vh;\n  background-size: cover;\n  background-position: center;\n  letter-spacing: 1px;\n  word-spacing: 1px;\n}\n\n.logoDiv {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 25px 0px 20px 0px;\n}\n\n.LOGO1 {\n  width: 160px;\n  height: 160px;\n}\n\n.LOGO1 img {\n  width: 100%;\n  height: 100%;\n}\n\n.LOGO2 {\n  width: 250px;\n}\n\n.LOGO2 img {\n  width: 100%;\n  height: 100%;\n}\n\n.title1 {\n  font-size: 32px;\n  font-weight: 600;\n  margin-top: 10px;\n}\n\n.title2 {\n  font-size: 14px;\n  font-weight: 600;\n  color: red;\n  letter-spacing: 5px;\n  margin-bottom: 10px;\n}\n\n.title3 {\n  font-size: 32px;\n}\n\n.title4 {\n  font-size: 12px;\n}\n\n.button {\n  background-color: white;\n  cursor: pointer;\n  padding: 8px 15px;\n  font-weight: 600;\n  margin: 10px 0px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
