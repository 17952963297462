import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Welcome from "./Component/Welcome";
import Login from "./Component/Login";
import MessageList from "./Component/MessageList";
import TermConditions from "./Component/TermConditions";

const ProtectedRoute = ({ element: Component, userDetails, ...rest }) => {
  if (userDetails) {
    return (
      <div>
        <Component {...rest} />
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};

function App() {
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

  return (
    <div>
      <Router>
        <div>
          <Routes>
            <Route
              path="/admin"
              element={
                userDetails ? <Navigate to="/message-list" /> : <Login />
              }
            />

            <Route path="/" element={<Welcome />} />

            <Route path="/terms-and-conditions" element={<TermConditions />} />

            <Route
              path="/message-list"
              element={
                <ProtectedRoute
                  element={MessageList}
                  userDetails={userDetails}
                />
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
