import { useState } from "react";
import "../App.css";
import LOGO1 from "../images/logo1.png";
import LOGO2 from "../images/SKYBIZ_ BLACK_LOGO.png";
import { Modal } from "antd";
import axios from "axios";
import { notification } from "antd";

function Welcome() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    nameOfBrand: "",
    notes: "",
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: message,
    });
  };

  const handleSubmit = () => {
    if (formData?.fullName.trim() === "") {
      openNotificationWithIcon("error", "Please enter your name");
      return;
    } else if (formData?.email.trim() === "") {
      openNotificationWithIcon("error", "Please enter your email");
      return;
    } else if (formData?.phone.trim() === "") {
      openNotificationWithIcon("error", "Please enter your phone number");
      return;
    } else if (formData?.nameOfBrand.trim() === "") {
      openNotificationWithIcon("error", "Please enter brand name");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}message/add`, formData)
      .then(() => {
        openNotificationWithIcon("success", "Successfully Added");
        setIsModalOpen(false);
      })
      .catch(() => {
        openNotificationWithIcon("error", "Something went Wrong");
      });
  };

  return (
    <div className="Home">
      {contextHolder}
      <Modal
        title="Submit Form"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        okText="Submit"
      >
        <div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="fullName"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      [event?.target?.name]: event?.target?.value,
                    })
                  }
                  value={formData?.fullName}
                  type="text"
                  name="fullName"
                  id="fullName"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Phone Number
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      [event?.target?.name]: event?.target?.value.trim(),
                    })
                  }
                  value={formData?.phone}
                  type="number"
                  name="phone"
                  id="phone"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      [event?.target?.name]: event?.target?.value.trim(),
                    })
                  }
                  value={formData?.email}
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="nameOfBrand"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Brand
              </label>
              <div className="mt-2.5">
                <input
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      [event?.target?.name]: event?.target?.value,
                    })
                  }
                  value={formData?.nameOfBrand}
                  type="text"
                  name="nameOfBrand"
                  id="nameOfBrand"
                  autoComplete="organization"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="notes"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Notes
              </label>
              <div className="mt-2.5">
                <textarea
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      [event?.target?.name]: event?.target?.value,
                    })
                  }
                  value={formData?.notes}
                  name="notes"
                  id="notes"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="logoDiv">
        <div className="LOGO1">
          <img src={LOGO1} alt="LOGO1" />
        </div>
        <div className="title1">BRAND4HUB</div>
        <div className="title2">FASHION STORE</div>
        <div className="title3">We are coming soon!!</div>
        <div className="title4">Stay tuned for something amazing</div>

        <div
          className="button text-[20px]"
          onClick={() => setIsModalOpen(true)}
        >
          REGISTER FOR BRAND LISTING
        </div>
      </div>

      <div className="fixed bottom-4 left-4 text-white">
        <a
          href="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          @Terms and conditions
        </a>
      </div>
    </div>
  );
}

export default Welcome;
